import AuthenticationContext from "contexts/AuthenticationContext";
import { createRef, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { initAnnotator } from "./annotator-utills";
import { BaseUser, CurrentUserState } from "types";
import $ from "jquery";
import config from "config";
import {
  displayShortTime,
  getTimezone,
  displayCondensedDate,
} from "pages/events/utils";
import { Link } from "react-router-dom";
import { sub } from "date-fns";
import DocumentDownloadCritiques from "pages/dashboard/components/modals/DocumentDownloadCritiques";
import { useSelector } from "react-redux";

export type SubmissionResponse = {
  document: {
    id: number;
    author: {
      id: number;
      pen_name: string;
    };
    title: string;
    contents: string;
  };
  group: {
    id: number;
    name: string;
    min_critique_words: number;
    url: string;
    is_admin: boolean;
  };
  notes: string;
  due_date: string;
  can_critique: boolean;
};

export type Critique = {
  id?: number;
  contents: string;
  submission: number;
  status: number;
};

export interface CritiqueResponse extends Critique {
  author: {
    id: number;
    pen_name: string;
    image: string;
  };
  created_at: string;
  finished_at: string;
}

export function AnnotatedContent({
  submissionId,
  submission,
  documentId,
  content,
  critiques,
  notes,
  userData,
  all = false,
  users,
  canDelete = true,
}: {
  submissionId: number;
  submission?: SubmissionResponse;
  documentId: number;
  content?: string;
  critiques?: CritiqueResponse[];
  notes?: string;
  userData?: any;
  all?: boolean;
  users?: any[];
  canDelete?: boolean;
}) {
  const [annotatorStarted, setAnnotatorStarted] = useState(false);
  const divRef = createRef<HTMLDivElement>();
  const authenticationContext = useContext(AuthenticationContext);
  const [annotator, setAnnotator] = useState<any>(null);
  const [oldUsers, setOldUsers] = useState<BaseUser[] | null>(null);
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const [downloadCritiqueModalOpen, setDownloadCritiqueModalOpen] =
    useState<boolean>(false);

  const cleanOldAnnotator = () => {
    if (!annotator) {
      return;
    }
    annotator.destroy();
    // remove all the margin annotations
    $(".secondary.margin-annotator-container").empty();
    // cleanup the selected highlights since destroy wasn't removing them
    $(".annotator-hl-uber").each((i, highlight) => {
      $(highlight).replaceWith($(highlight).text());
    });
    setAnnotatorStarted(false);
  };

  const initializeAnnotator = () => {
    if (divRef.current && authenticationContext.token) {
      cleanOldAnnotator();
      const ann = initAnnotator(
        userData,
        submissionId,
        divRef.current,
        authenticationContext.token,
        all,
        users,
        canDelete
      );
      setOldUsers(users || null);
      setAnnotator(ann);
    }
  };

  useEffect(() => {
    if (userData.id && submissionId && users !== oldUsers && (!all || users)) {
      initializeAnnotator();
    }
  }, [
    divRef,
    userData,
    submissionId,
    annotatorStarted,
    authenticationContext,
    users,
  ]);

  return (
    <Row>
      <Col xs={12} md={8} className="p-4 border-l border-r border-b">
        <Row>
          <Col sm={8}>
            {notes && (
              <div className="submission-notes">
                <i
                  dangerouslySetInnerHTML={{
                    __html: notes || "",
                  }}
                ></i>
              </div>
            )}
          </Col>
          <Col sm={4} style={{ textAlign: "right" }}>
            {!all && (
              <>
                <div className="text-sm text-gray-500">
                  Due{" "}
                  {submission && (
                    <span>
                      {displayCondensedDate(submission.due_date)}{" "}
                      {displayShortTime(submission.due_date)}
                    </span>
                  )}{" "}
                  ({getTimezone()})
                </div>
                <Link
                  to={`/critique/all_for/${submissionId}/Submission/`}
                  className="text-blue-600 text-sm"
                  rel="ugc"
                >
                  View all critiques
                </Link>
                <div className="mt-2 pb-4">
                  {submission &&
                    userState.data?.id > 0 &&
                    (submission?.document.author.id === userState.data?.id ? (
                      <>
                        <a
                          href="#"
                          className="float-right hover-underline"
                          style={{
                            fontSize: "15px",
                            marginTop: 15,
                            marginRight: 10,
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setDownloadCritiqueModalOpen(true);
                          }}
                        >
                          Download Critique
                        </a>
                        <DocumentDownloadCritiques
                          show={downloadCritiqueModalOpen}
                          onHide={() => setDownloadCritiqueModalOpen(false)}
                          submission={{
                            ...submission,
                            id: submissionId,
                            finished_critiques:
                              critiques?.map((c) => {
                                return {
                                  author_name: c.author.pen_name,
                                  author_id: c.author.id,
                                };
                              }) || [],
                          }}
                        />
                      </>
                    ) : (
                      <a
                        href={`${config.BASE_API_URL}document_to_pdf/${documentId}/?print=pdf`}
                        style={{ float: "right" }}
                      >
                        <div
                          style={{
                            width: 40,
                            height: 33,
                            background: `url(${config.BASE_API_URL}static/img/sprite.png) -500px -200px`,
                          }}
                        />
                      </a>
                    ))}
                </div>
              </>
            )}
          </Col>
        </Row>

        <div ref={divRef}>
          <div
            id="annotated_content"
            className="flex-1 pr-8 annotated_content"
            dangerouslySetInnerHTML={{
              __html: content || "",
            }}
          ></div>
        </div>
      </Col>
      <Col xs={0} md={4} className="margin-annotator-wrapper">
        {/* Margin Viewer container */}
        <div className="w-64 pl-4 margin-annotator-container">
          <div className="secondary margin-annotator-container"></div>
        </div>
      </Col>
    </Row>
  );
}
