import AuthenticationContext from "contexts/AuthenticationContext";
import { on } from "events";
import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";

export default function DeleteFileModal({
  file,
  groupId,
  show,
  onHide,
}: {
  file: { id: number; name: string };
  groupId: number;
  show: Boolean;
  onHide: (deleted: Boolean) => void;
}) {
  const authenticationContext = useContext(AuthenticationContext);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const url = `api/v1.0/group/${groupId}/files/${file.id}/`;
    sendRequest({
      url,
      method: "DELETE",
      token: authenticationContext.token || "",
      body: null,
    })
      .then((response) => {
        onHide(true);
        toast.success("File deleted succesfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch((error: any) => {
        toast.error(error.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  return (
    <Modal show={show} centered onHide={() => onHide(false)}>
      <Modal.Header>
        <Modal.Title>Confirm Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete <b>{file.name}</b>?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="primary purple" onClick={onSubmit}>
          Delete
        </Button>
        <Button
          variant="secondary"
          className="secondary grey"
          onClick={() => onHide(false)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
