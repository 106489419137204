import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";
import { DiscussionComment } from "types";

export default function EditDiscussionCommentModal({
  comment,
  show,
  onHide,
}: {
  comment: DiscussionComment;
  show: Boolean;
  onHide: (deleted: Boolean) => void;
}) {
  const authenticationContext = useContext(AuthenticationContext);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const textArea = (e.currentTarget as any)[0] as HTMLTextAreaElement;
      const text = textArea.value;
      await sendRequest({
        method: "PATCH",
        url: `api/v1.0/group/${comment.target_instance_id}/comments/${comment.id}/`,
        token: authenticationContext.token || "",
        body: JSON.stringify({ text: text }),
      });
      onHide(true);
      toast.success("Comment updated successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error: any) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <Modal show={show} centered onHide={() => onHide(false)}>
      <form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Edit Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="textarea-holder">
            <textarea
              name="text"
              cols={40}
              rows={2}
              style={{ height: 30 }}
              maxLength={5000}
              placeholder="Ask a question, answer one or make a comment."
              required
              id="id_text"
              defaultValue={comment.text}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="primary purple" type="submit">
            Save
          </Button>
          <Button
            variant="secondary"
            className="secondary grey"
            onClick={() => onHide(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
