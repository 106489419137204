import { Button, Card, Col, Overlay, Row } from "react-bootstrap";
import "./GroupDetail.scss";
import config from "config";
import {
  FormEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useParams } from "react-router-dom";
import { CurrentUserState, DiscussionComment, FullGroup, Group } from "types";
import AuthenticationContext from "contexts/AuthenticationContext";
import sendRequest from "services/dataService";
import GroupDetailTabs from "components/groups/details/groupDetailTabs";
import ReactPlaceholder from "react-placeholder";
import DiscussionCommentComponent from "./discussion/comment-component/DiscussionCommentComponent";
import PaginationButtons from "components/pagination-buttons/PaginationButtons";
import NewMessageForm from "./discussion/new-message-component/DiscussionNewCommentComponent";
import "intro.js/introjs.css";
import introJs from "intro.js";
import { useDispatch, useSelector } from "react-redux";
import { TourStep } from "intro.js/src/packages/tour/steps";
import { loaduser } from "redux/userReducer";
import GroupAdminOptions from "pages/dashboard/groups/GroupAdminOptions/GroupAdminOptions";

const GroupMemberCol = ({
  member,
}: {
  member?: { id: number; avatar?: string; pen_name: string };
}) => {
  return (
    <Col sm={6}>
      <div>
        <ReactPlaceholder
          type="rect"
          ready={Boolean(member?.avatar)}
          style={{ width: 91, height: 91 }}
        >
          <Link to={`/profile/view/${member?.id}`}>
            <img src={member?.avatar} className="w-full" />
          </Link>
        </ReactPlaceholder>
      </div>
      <p className="text-sm">
        <ReactPlaceholder type="text" rows={1} ready={Boolean(member)}>
          <Link to={`/profile/view/${member?.id}`} style={{ color: "black" }}>
            {member?.pen_name}
          </Link>
        </ReactPlaceholder>
      </p>
    </Col>
  );
};

export default function GroupDetailPage() {
  const [selectedTab, setSelectedTab] = useState("critique");
  const { id, slug } = useParams() as any;
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const [group, setGroup] = useState<FullGroup | null>(null);
  const authenticationContext = useContext(AuthenticationContext);
  const [comments, setMessages] = useState<DiscussionComment[] | null>(null);
  const [currentPage, setCurrentPage] = useState<number | null>(1);
  const [totalPages, setTotalPages] = useState<number | null>(null);
  const dispatch = useDispatch();
  const optionsButtonRef = useRef(null);
  const [showOptions, setShowOptions] = useState(false);

  const steps: Partial<TourStep>[] = [
    {
      intro:
        "<h4>Groups page</h4>This is your group’s page. All submissions, critiques and" +
        " discussion are private to your group. " +
        "Click your group name to see your group’s public profile.",
    },
    {
      element: "#critique-panel",
      intro:
        "<h4>Critique panel</h4>This is the hub of critiquing activity. " +
        "The main exchange of critique comments occurs on the Critique tab. " +
        "After a submission’s critique period ends, it moves to the Review tab " +
        "for group review and Q&A. View your group’s submission settings on the " +
        "Submissions tab.",
      position: "left",
    },
    {
      element: "#critique-tab",
      intro:
        "<h4>Critique tab</h4>Give critiques and view all group member comments here. " +
        "The status indicates whether a critique is not started, started or complete.<br>" +
        "Click a document title to critique it. You will critique a clean copy-- " +
        "you won’t see other people’s comments while editing. " +
        "If you want to see other people’s comments while you critique, " +
        "click “View All” in the critique interface. " +
        "This opens up group comments in a new window. ",
      position: "bottom",
    },
    {
      element: "#review-tab",
      intro:
        "<h4>Review tab</h4>The Review period is a time when all group members can see a " +
        "submission’s critiques and participate in Q&A. " +
        "Your documents are removed from the page at the end of the review period," +
        " but will still be visible on your Dashboard.<br>" +
        "Q&A lets you ask for clarifications on a person’s critique or advice on how to " +
        "move forward. Resist the urge to defend your work & and keep this a place for " +
        "questions. Scroll down to see Q&A discussion at the bottom of a document.",
      position: "bottom",
    },
    {
      element: "#submissions-tab",
      intro:
        "<h4>Submissions</h4>View your group’s submission schedule or rotation here. " +
        "Expand Submission Settings to see the deadline windows for manuscript " +
        "critique and review. View maximum word count settings and rules on maximum " +
        "levels of sexual or violent content. <br>" +
        "Settings are customizable. Discuss settings with your group and the group leader" +
        " can make changes as needed.",
      position: "bottom",
    },
    {
      element: "#files-tab",
      intro:
        "<h4>Files</h4>Post reference documents here, such as manuscript synopses " +
        "(for those workshopping longer works), conference notes you’d like to share," +
        " or a list of recommended editors/agents.",
      position: "top",
    },
    {
      element: "#news-feed-title",
      intro:
        "<h4>Discussion</h4>Talk about agents, classes and writing tips with your " +
        "group members here.",
      position: "top",
    },
    {
      element: "#message-button",
      intro: "<h4>Send message</h4>Send an email to your entire group.",
      position: "right",
    },
    {
      element: "#goals-container",
      intro:
        "<h4>Goals</h4>Work with your group to set shared goals and post them here. " +
        "Your group leader can edit this area, but everyone should contribute " +
        "to the discussion. ",
      position: "right",
    },
    {
      element: "#group-list",
      intro:
        "<h4>Members</h4>Click on a member’s name to see their public profile or send a message.",
      position: "right",
    },
  ];

  async function turnOffShowGroupIntro() {
    const url = `${config.BASE_API_URL}api/v1.0/user/current/`;
    const response = await sendRequest({
      url,
      method: "PATCH",
      token: authenticationContext.token || "",
      body: JSON.stringify({
        show_group_intro: false,
      }),
    });
    dispatch(loaduser(authenticationContext.token));
  }

  function startIntro() {
    var intro = introJs();
    intro.setOptions({
      highlightClass: "introjs-highlight",
      steps: steps,
      nextLabel: "Next →",
      prevLabel: "← Back",
    });
    intro.onExit(function () {
      if (userState.data.show_group_intro) {
        turnOffShowGroupIntro();
      }
    });
    intro.start();
    return intro;
  }

  const updateExpandDiscussion = (value: boolean) => {
    const token = authenticationContext.token || "";
    sendRequest({
      method: "PATCH",
      url: `api/v1.0/group/${id}/membership/`,
      body: JSON.stringify({ expand_discussion: value }),
      token,
    }).then((response) => {
      setGroup({ ...group, expand_discussion: value } as FullGroup);
      setMessages([]);
      fetchGroupMessages(id);
    });
  };

  const fetchGroup = (id: number) => {
    const url = `api/v1.0/group/${id}/`;
    sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    }).then((response) => {
      setGroup(response);
    });
  };

  useEffect(() => {
    if (userState.data.show_group_intro) {
      startIntro();
    }
  }, [userState.data]);

  const fetchGroupMessages = (id: number) => {
    const PAGE_SIZE = 10;
    const url = `api/v1.0/group/${id}/comments/?page=${currentPage || 1}`;
    sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    }).then((response) => {
      setMessages(response.results);
      setTotalPages(Math.ceil(response.count / PAGE_SIZE));
    });
  };

  useEffect(() => {
    setSelectedTab("critique");
    fetchGroup(id);
    fetchGroupMessages(id);
  }, [id]);

  useEffect(() => {
    if (currentPage) {
      fetchGroupMessages(id);
    }
  }, [currentPage]);

  const baseUrl = config.BASE_API_URL || "";
  const imgUrl = group?.avatar
    ? group?.avatar?.startsWith("http")
      ? group?.avatar
      : baseUrl + group?.avatar
    : baseUrl + "/static/img/bg-group-profile-picture.png";

  return (
    <div className="container mx-auto p-4">
      <Row>
        {/* Left column */}
        <Col sm={3}>
          <Card className="sidebar">
            <Card.Body>
              <ReactPlaceholder
                type="rect"
                className="group-picture"
                ready={group != null}
                style={{ minHeight: "200px" }}
              >
                <img src={imgUrl} className="group-picture" />
              </ReactPlaceholder>
              <Button
                variant="primary"
                className="purple"
                id="message-button"
                href={`${config.BASE_API_URL}messages/compose/${group?.members
                  ?.map((member) => member.id.toString())
                  .join("+")}/`}
              >
                Message Group
              </Button>
              <div className="section" id="goals-container">
                <h3 className="subt">Goals</h3>
                <p className="text-sm text-gray-600 mb-4">
                  <ReactPlaceholder type="text" rows={4} ready={group != null}>
                    {group?.goal}
                  </ReactPlaceholder>
                </p>
              </div>
              <div className="section">
                <h3 className="subt">
                  <Link to="/resources/how-to-s/">Tech Tutorial</Link>
                </h3>
              </div>
              <div className="section">
                <h3 className="subt">Description</h3>

                <ReactPlaceholder type="text" rows={4} ready={group != null}>
                  <></>
                </ReactPlaceholder>
                <p
                  className="text-sm text-gray-600 mb-4"
                  dangerouslySetInnerHTML={{ __html: group?.description || "" }}
                ></p>
              </div>
              <div className="section group-members-list" id="group-list">
                <h3 className="subt">Members</h3>
                <Row>
                  {group ? (
                    group?.members?.map((member) => (
                      <GroupMemberCol key={member.id} member={member} />
                    ))
                  ) : (
                    <>
                      <GroupMemberCol key={1} />
                      <GroupMemberCol key={2} />
                      <GroupMemberCol key={3} />
                    </>
                  )}
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>

        {/* Right column */}
        <Col sm={9}>
          <a
            id="replay_tour"
            className="pull-right"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              startIntro();
            }}
          >
            <span
              className="ico-help"
              data-placement="left"
              title="Take the tour."
            ></span>
          </a>

          <h1
            className="font-bold mb-4 hover-underline"
            style={{ fontSize: "26px" }}
          >
            <ReactPlaceholder
              type="textRow"
              ready={group != null}
              style={{ minHeight: "26px", maxWidth: 200 }}
            >
              <a href={`${config.BASE_API_URL}group/public/${id}/${slug}/`}>
                {group ? group.name : "" + " "}
              </a>
              {group && <GroupAdminOptions group={group} />}
            </ReactPlaceholder>
          </h1>
          <div className="flex justify-between items-center mb-4">
            <div className="w-full" id="critique-panel">
              <GroupDetailTabs
                groupId={id}
                has_enough_credits={Boolean(
                  group?.critique_estimate ||
                    0 <= (group?.credits_available || 0)
                )}
              />
            </div>
          </div>

          <div
            className="w-full"
            style={{ height: 2, backgroundColor: "#efefef" }}
          />
          <div className="heading-container mt-2">
            <a
              className="float-right options-toggle"
              ref={optionsButtonRef}
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setShowOptions(!showOptions);
              }}
            >
              <span className="underline-link mr-2">Options</span>
              <span className="plus-icon"></span>
            </a>

            <Overlay
              target={optionsButtonRef.current}
              show={showOptions}
              placement="bottom-end"
            >
              <div
                className="overflow-container"
                style={{
                  backgroundColor: "rgba(212, 211, 202, 1)",
                  padding: "10px",
                  marginTop: "10px",
                  borderRadius: "3px",
                  textAlign: "right",
                }}
              >
                <div className="content">
                  <div
                    className="subtext"
                    style={{
                      fontSize: "12px",
                      lineHeight: "14px",
                      fontStyle: "italic",
                      marginBottom: "5px",
                    }}
                  >
                    Set your default preferences for viewing discussions:
                  </div>
                  <div className="checkbox-holder" style={{ fontSize: "15px" }}>
                    <span>Expand All: </span>
                    <input
                      type="radio"
                      name="subComments"
                      checked={group?.expand_discussion}
                      onChange={(e) => {
                        updateExpandDiscussion(e.target.checked);
                      }}
                    />
                    <span> Collapse All: </span>
                    <input
                      type="radio"
                      name="subComments"
                      checked={!group?.expand_discussion}
                      onChange={(e) => {
                        updateExpandDiscussion(!e.target.checked);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Overlay>
            <h2 id="news-feed-title">Group Discussion</h2>
          </div>
          <div>
            <NewMessageForm
              groupId={id}
              onPostMessage={() => {
                setCurrentPage(null);
                setCurrentPage(1);
              }}
            />
            {group &&
              comments?.map((comment) => (
                <DiscussionCommentComponent
                  comment={comment}
                  isAdmin={group?.is_admin}
                  expandByDefault={group?.expand_discussion}
                  onPinUnpin={(pinned: boolean) => {
                    if (pinned) {
                      setCurrentPage(null);
                      setCurrentPage(1);
                    } else {
                      fetchGroupMessages(id);
                    }
                  }}
                  onDelete={() => {
                    fetchGroupMessages(id);
                  }}
                  onReply={() => {
                    fetchGroupMessages(id);
                  }}
                />
              ))}
            {currentPage != null && totalPages != null && (
              <div className="pagination">
                <PaginationButtons
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={(page) => setCurrentPage(page)}
                  firstPageLabel="Newest"
                  lastPageLabel="Oldest"
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
