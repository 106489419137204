import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";

export default function DeleteSubmissionModal({
  submission,
  show,
  onHide,
}: {
  submission: { id: number; title: string };
  show: Boolean;
  onHide: (deleted: Boolean) => void;
}) {
  const authenticationContext = useContext(AuthenticationContext);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await sendRequest({
        url: `api/v1.0/submission/${submission.id}/`,
        method: "DELETE",
        token: authenticationContext.token || "",
        body: null,
      });
      onHide(true);
      toast.success("Submission removed successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error: any) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <Modal show={show} centered onHide={() => onHide(false)}>
      <Modal.Header>
        <Modal.Title>Confirm Remove Submission</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to remove <b>{submission.title}</b> from this
        group?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="primary purple" onClick={onSubmit}>
          Remove
        </Button>
        <Button
          variant="secondary"
          className="secondary grey"
          onClick={() => onHide(false)}
        >
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
