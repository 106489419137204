import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface CharCountProps {
  maxLength: number;
  inputName: string;
  register: any;
  errors: any;
  label: string;
  fieldDescription?: string;
  defaultValue?: string;
  placeholder?: string;
  styledText?: boolean;
  control?: any;
}

const CharCountInput: React.FC<CharCountProps> = ({
  maxLength,
  inputName,
  register,
  errors,
  label,
  control,
  fieldDescription = '',
  defaultValue = '',
  placeholder = '',
  styledText = false,
}) => {
  const [charCount, setCharCount] = useState(maxLength - (defaultValue || '').length);
  const { setValue } = useForm();
  const quillRef = useRef<ReactQuill | null>(null);

  const formats = [
    'font','size',
    'bold','italic','underline','strike',
    'color','background',
    'script',
    'header','blockquote','code-block',
    'indent','list',
    'direction','align',
    'link','image','video','formula',
  ]

  useEffect(() => {
    setCharCount(maxLength - (defaultValue || '').length);
  }, [maxLength, defaultValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const inputLength = event.target.value.length;
    if (inputLength <= maxLength) {
      setCharCount(maxLength - inputLength);
	  }
  };

  return (
    <Row>
      <Col md={{ span: 3, offset: 0 }}>
      <label>{label}</label>
      {fieldDescription && <p className="field-description">{fieldDescription}</p>}
      </Col>
      <Col md={{ span: 9, offset: 0 }}>
        {errors[inputName] && <span>This field is required</span>}
        {styledText ? (
          <Controller
            name={inputName}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <ReactQuill
                theme="snow"
                value={field.value || ''}
                onChange={(value) => {
                  field.onChange(value);
                  const quill = quillRef.current?.getEditor();
                  const contents = quill?.root.innerHTML;
                  setValue(inputName, contents);
                }}
                placeholder={placeholder}
                formats={formats}
                ref={quillRef}
              />
            )}
          />
        ) : (
          <textarea
            {...register(inputName)}
            className="col-md-9"
            placeholder={placeholder}
            maxLength={maxLength}
            onChange={handleInputChange}
            defaultValue={defaultValue}
          />
        )}
        <i>{charCount} Characters Remaining</i>
        <div className="char-count bio-chars-left"></div>
      </Col>
    </Row>
  );
};

export default CharCountInput;