import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  useForm,
  SubmitHandler,
  Controller,
} from "react-hook-form";
import AuthenticationContext from "contexts/AuthenticationContext";
import {
  Col,
  Container,
  Row,
} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import Select, { MultiValue } from "react-select";

import config from "config";
import useFetch from "custom-hooks/useFetch";
import GenreSelector from "components/genre-selector/GenreSelector";

import "../GroupEditProfile.scss";
import { FullGroup, BaseUser } from "types";
import CharCountInput from "../../../../components/char-count-input/CharCountInput";

function getGenreListIds(genreObject: any) {
  let genres2_ids: any = [];

  genreObject.forEach((genre_super_category: any) =>
    genre_super_category.children.forEach((category: any) =>
      category.children.forEach(function (genre: any) {
        if (genre.selected === true) {
          genres2_ids.push(genre.id);
        }
      })
    )
  );

  return genres2_ids;
}

const GroupEditProfileForm = ({ groupData }: { groupData: FullGroup }) => {
  const history = useHistory();
  const [state, fetchData] = useFetch();
  const user = useSelector((state: any) => state.user);
  const authenticationContext = useContext(AuthenticationContext);
  const [typeOfGroup, setTypeOfGroup] = useState(groupData.type_of_group);
  const [critiqueManager, setCritiqueManager] = useState(
    groupData.critique_manager
  );
  const [isMaxWordsChecked, setIsMaxWordsChecked] = useState(false);
  const [maxWords, setMaxWords] = useState<number | string>("");
  const [genres, setGenres] = useState(groupData.genres2_set);
  const [avatarUrl, setAvatarUrl] = useState(groupData.avatar);
  const [activeUsers, setActiveUsers] = useState<BaseUser[]>();
  const [newOwners, setNewOwners] = useState<BaseUser[]>(groupData.owners);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FullGroup>({
    defaultValues: groupData,
    mode: "onBlur",
  });

  const onAvatarChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("avatar", file);
      formData.append("group_id", groupData.id.toString());

      try {
        const response = await axios.put(
          `${config.BASE_API_URL}group/api/change-avatar/`,
          formData,
          {
            headers: {
              Authorization: "Token " + (authenticationContext.token || ""),
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setAvatarUrl(response.data.avatar);
        toast.success("Avatar updated successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } catch (error) {
        toast.error("Failed to update avatar.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    }
  };

  const onSubmit: SubmitHandler<FullGroup> = async (data) => {
    data.genres2_set = getGenreListIds(genres);
    data.owners = newOwners;

    fetchData({
      url: "group/api/edit/",
      method: "PUT",
      token: authenticationContext.token,
      body: JSON.stringify(data),
    })
      .then(() => {
        toast.success("Success! Your submission has been saved.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        const slug = groupData.name.replace(/\s+/g, "-").toLowerCase();
        // redirect to the group page after successful submission
        history.push(`/group/${groupData.id}/${slug}`);
      })
      .catch((error) => {
        // Assuming error.response contains the response body with errors
        if (error.message) {
          let errorData;
          try {
            // Try to parse error.response.data if it's a string
            errorData =
              typeof error.message === "string"
                ? JSON.parse(error.message)
                : error.response.data;
          } catch (e) {
            // If parsing fails, handle the error or assign a default value to errorData
            errorData = {
              message:
                "An error occurred, but the details could not be parsed.",
            };
          }

          let errorMessage = "Failed to save changes.";
          if (typeof errorData === "object" && errorData !== null) {
            if (errorData.message) {
              // If errorData contains a message property, use it as the errorMessage
              errorMessage = errorData.message;
            } else {
              // If not build the error message based on the properties of errorData
              errorMessage = Object.entries(errorData)
                .map(([field, value]) => {
                  // Make sure value is an array before trying to join its elements.
                  const errors = Array.isArray(value)
                    ? value.join(", ")
                    : "Unknown error";
                  return `${field}: ${errors}`;
                })
                .join("\n");
            }
          }
          toast.error(errorMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        } else {
          toast.error(
            "Failed to save changes and could not parse error details.",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }
      });
  };

  const typeOfGroupOptions = [
    { value: 0, label: "Ongoing critique group." },
    { value: 1, label: "Pop up (short-term) critique group." },
    { value: 2, label: "Book Studies." },
    { value: 3, label: "Writing Challenges and Accountability Groups." },
    { value: 4, label: "Community/Discussion groups." },
    { value: 5, label: "Professional workshop." },
  ];

  const sexualContentOptions = [
    { value: 1, label: "Minimal - nothing more than kissing." },
    {
      value: 2,
      label:
        "Mild - may or may not include lovemaking. Sex is implied rather than explicit.",
    },
    {
      value: 3,
      label:
        "Hot - ranges from lovemaing to explicit sex (most romance novels).",
    },
    { value: 4, label: "Scorcher - borders on erotic. Very graphic sex." },
  ];

  const violenceOptions = [
    { value: 0, label: "None." },
    { value: 1, label: "Minor - little or brief violence." },
    { value: 2, label: "Mild - several scenes or scenes of longer duration." },
    { value: 3, label: "Significant - descriptive violence throughout." },
    { value: 4, label: "Heavy - violence is intense or gory." },
  ];

  const critiqueManagerOptions = [
    { value: 1, label: "Auto Approve" },
    { value: 2, label: "Manual Approve" },
  ];

  useEffect(() => {
    fetchData({
      url: "api/v1.0/user/active/",
      method: "GET",
      token: authenticationContext.token,
    }).then((response) => {
      setActiveUsers(response);
    });
  }, [authenticationContext.token]);

  const transformedUsers = activeUsers?.map(user => ({
    value: user.id,
    label: user.pen_name
  }));

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsMaxWordsChecked(e.target.checked);
    if (e.target.checked) {
      setMaxWords("");
    } else {
      setMaxWords(0);
    }
  };

  const handleMaxWordsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMaxWords(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="edit-group">
      <Container className="container profile-edit">
        <Row>
          <Col md={{ span: 12, offset: 0 }}>
            <h1>{groupData ? "EDIT" : "CREATE A"} GROUP</h1>
            {groupData && <a href={groupData.url} target="_blank" rel="noopener noreferrer">View group</a>}
          </Col>
        </Row>
        <div className="profile-edit">
          <Row>
            <Col md={3}>
              <h2>Basics</h2>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Group name*</label>
            </Col>
            <Col md={5}>
              <input
                type="text"
                className="form-control"
                placeholder="Group Name"
                {...register("name")}
              />
              <br></br>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <label>Group profile photo</label>
            </Col>
            <Col md={9}>
              <Row>
                <Col md={3} id="img-container">
                  {groupData.avatar ? (
                    <img className="profile-picture" src={avatarUrl} />
                  ) : (
                    <>
                      <img
                        className="profile-picture"
                        src=""
                        style={{ display: "none" }}
                      />
                      <span className="group-profile-picture"></span>
                    </>
                  )}
                </Col>
                <Col className="col-md-7">
                  Change your group profile photo
                  <br />
                  <br />
                  <input type="file" onChange={onAvatarChange} />
                  <div id="file-uploader"></div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <label>Group owners*</label>
            </Col>
            <Col md={9}>
              {groupData && 
                <Select
                  isMulti
                  options={transformedUsers}
                  defaultValue={groupData.owners.map((owner: any) => ({
                    value: owner.id,
                    label: owner.pen_name,
                  }))}
                  onChange={(selectedOption: any) => {
                    setNewOwners(selectedOption.map((option: any) => option.value));
                  }}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      background: "#ebebeb",
                    }),
                    multiValue: (baseStyles, state) => ({
                      ...baseStyles,
                      backgroundColor: "#005b7f",
                      color: "white",
                    }),
                    multiValueLabel: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "white",
                    }),
                    multiValueRemove: (baseStyles, state) => ({
                      ...baseStyles,
                      color: "#999",
                      ':hover': {
                        backgroundColor: "darkblue",
                        color: "#999",
                      },
                    }),
                  }}
                />
              }
            </Col>
          </Row>

          <Row>
            <Col md={3} className="col-md-3">
              <label>Type of Group</label>
            </Col>
            <Col md={6}>
              <Select
                options={typeOfGroupOptions}
                {...register("type_of_group")}
                onChange={(selectedOption: any) => {
                  setTypeOfGroup(selectedOption.value);
                }}
                value={typeOfGroupOptions.find(
                  (option) => option.value === groupData.type_of_group
                )}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    background: "#ebebeb",
                  }),
                }}
              />
              <br />
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <label>Group genres*</label>
            </Col>
            <Col md={9}>
              <Controller
                name="genres2_set"
                control={control}
                render={({ field: { value, onChange, onBlur } }) => {
                  return (
                    <GenreSelector
                      genres={genres}
                      onChange={(e: any) => {
                        setGenres(e);
                      }}
                    />
                  );
                }}
              />
            </Col>
          </Row>

          <CharCountInput
            styledText
            control={control}
            maxLength={5000}
            inputName="description"
            register={register}
            errors={errors}
            defaultValue={groupData.description}
            label="Group Description *"
          />

          <CharCountInput
            maxLength={1200}
            inputName="admissions_policies"
            register={register}
            errors={errors}
            defaultValue={groupData.admissions_policies}
            label="Admissions Policies"
          />

          <CharCountInput
            maxLength={1200}
            inputName="members_requirements"
            register={register}
            errors={errors}
            defaultValue={groupData.members_requirements}
            label="What we're looking for in members"
          />

          <Row className="row">
            <Col md={3} className="col-md-3">
              <label>Share with Inked Voices site visitors?</label>
            </Col>
            <Col md={9}>
              <input {...register("share_with_visitors")} type="checkbox" /> Yes
            </Col>
          </Row>

          <CharCountInput
            styledText
            control={control}
            maxLength={20000}
            inputName="submission_schedule"
            register={register}
            errors={errors}
            defaultValue={groupData.submission_schedule}
            label="Submission Schedule"
          />

          <CharCountInput
            maxLength={1200}
            inputName="inactivity_policy"
            register={register}
            errors={errors}
            defaultValue={groupData.inactivity_policy}
            label="Inactivity Policy"
          />

          <CharCountInput
            maxLength={1200}
            inputName="goal"
            register={register}
            errors={errors}
            defaultValue={groupData.goal}
            label="Group Goals*"
          />

          <Row>
            <Col md={3}>
              <label>What is your target group size?</label>
            </Col>
            <Col md={9}>
              <Row className="row simple">
                <Col md={1}>
                  <input
                    type="number"
                    id="id_min_size"
                    {...register("min_size")}
                  />
                </Col>
                <Col md={1}>
                  <p className="space">to</p>
                </Col>
                <Col md={1}>
                  <input
                    type="number"
                    id="id_max_size"
                    {...register("max_size")}
                  />
                </Col>
                <Col md={1}>
                  <p className="space">members</p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <label>Are you accepting new group members?</label>
            </Col>
            <Col md={9}>
              <input {...register("is_recruiting")} type="checkbox" /> Yes
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <label>
                Does your group have an outside site (Facebook, Meetup, etc.)?
              </label>
            </Col>
            <Col md={9} className="col-md-9">
              <Row className="simple">
                <Col md={5}>
                  <input
                    type="url"
                    {...register("facebook")}
                    className="inline-form-input"
                    placeholder="https://www.facebook.com/JoeDoe"
                  />
                  <input
                    type="url"
                    {...register("website")}
                    className="inline-form-input"
                    placeholder="https://personalwebsite.com"
                  />
                </Col>
                <Col md={5}>
                  <input
                    type="url"
                    {...register("meetup")}
                    className="inline-form-input"
                    placeholder="http://www.meetup.com/Joe-Doe/"
                  />
                  <br />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>

      <div className="bottom-edge-white"></div>
      <div className="prefooter-one">
        <br />
        <Container className="profile-edit">
          <Row>
            <Col md={3}>
              <h2>Submissions</h2>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <label>
                What level of sexual content is acceptable in group submissions?
                *
              </label>
            </Col>
            <Col md={9}>
              <ul id="id_sexual_content" className="custom-checkbox">
                {sexualContentOptions.map((option, index) => (
                  <li
                    key={`id_sexual_content_${index}`}
                    className="custom-checkbox"
                  >
                    <label className="custom-checkbox">
                      <input
                        {...register("sexual_content")}
                        type="radio"
                        value={option.value}
                        defaultChecked={
                          option.value === groupData.sexual_content
                        }
                        className="custom-checkbox"
                      />{" "}
                      {option.label}
                    </label>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <label>
                What level of violence is acceptable in submissions to the
                group? *
              </label>
            </Col>
            <Col md={9}>
              <ul id="id_violence" className="custom-checkbox">
                {violenceOptions.map((option, index) => (
                  <li key={`id_violence_${index}`} className="custom-checkbox">
                    <label className="custom-checkbox">
                      <input
                        {...register("violence")}
                        type="radio"
                        value={option.value}
                        defaultChecked={option.value === groupData.violence}
                        className="custom-checkbox"
                      />{" "}
                      {option.label}
                    </label>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>

          <Row className="word-count">
            <Col md={3}>
              <label>Word count</label>
            </Col>
            <Col md={9}>
              Is there a maximum for submissions?
              <ul>
                <li>
                  <input
                    type="checkbox"
                    id="maximum_check"
                    checked={isMaxWordsChecked}
                    onChange={handleCheckboxChange}
                  />{" "}
                  Yes
                  {isMaxWordsChecked && (
                    <span className="inline" id="word_input">
                      <input
                        type="number"
                        id="id_max_words"
                        value={maxWords}
                        {...register("max_words")}
                        onChange={handleMaxWordsChange}
                      />{" "}
                      words
                    </span>
                  )}
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bottom-edge-light"></div>
      <div className="prefooter-two">
        <br />
        <Container className="profile-edit">
          <Row>
            <Col md={3}>
              <h2>Advanced Settings</h2>
            </Col>
          </Row>
          <Row className="row">
            <Col md={3}>
              <label>Critique ratio</label>
            </Col>
            <Col md={9} style={{ lineHeight: "30px" }}>
              How many credits will group members use to submit their work? Each
              critique given earns 1 credit.
              <br />
              <input
                type="number"
                id="id_critique_estimate"
                {...register("critique_estimate")}
              />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Critique window</label>
            </Col>
            <Col md={9} style={{ lineHeight: "30px" }}>
              How many days do group members have to critique submissions?
              <br />
              <Col md={1} className="horizontal-align">
                <input
                  type="number"
                  id="id_critique_time_window"
                  {...register("critique_time_window")}
                />{" "}
                Days
              </Col>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Review window</label>
            </Col>
            <Col md={9} style={{ lineHeight: "30px" }}>
              How long can members review a document after a critique period?
              <br />
              <Col md={1} className="horizontal-align">
                <input
                  type="number"
                  id="id_review_time_window"
                  {...register("review_time_window")}
                />{" "}
                Days
              </Col>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Minimum critique length</label>
            </Col>
            <Col md={9} style={{ lineHeight: "30px" }}>
              If there is a word count minimum for the "Overall feedback"
              section, set it here.
              <br />
              <input
                type="number"
                id="id_min_critique_words"
                {...register("min_critique_words")}
              />
              <br />
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <label>Critique manager</label>
            </Col>
            <Col md={9} style={{ lineHeight: "30px" }}>
              How do you prefer manage the approval of critiques after deadline?
              <br />
              <Col md={3}>
                <Select
                  options={critiqueManagerOptions}
                  {...register("critique_manager")}
                  onChange={(selectedOption: any) => {
                    setCritiqueManager(selectedOption.value);
                  }}
                  value={critiqueManagerOptions.find(
                    (option) => option.value === groupData.critique_manager
                  )}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      background: "#ebebeb",
                    }),
                  }}
                />
              </Col>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 9, offset: 3 }} className="main-btn">
              <input type="submit" className="btn purple" value="Save Group" />
              <a
                href="{% if group_id %}{% url 'view_group2' group_id %}{% else %}{% url 'dashboard' %}{% endif %}"
                className="btn cancel"
                style={{ marginLeft: "1%" }}
              >
                Cancel
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </form>
  );
};

export default GroupEditProfileForm;
