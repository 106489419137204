import React, { useEffect, useState, FormEvent, useContext, useRef } from "react";
import { Button, Modal, Form, Alert } from "react-bootstrap";
import { toast } from "react-toastify";

import AuthenticationContext from "contexts/AuthenticationContext";
import useFetch from "custom-hooks/useFetch";


interface User {
  pk: number;
  pen_name: string;
  email: string;
}

interface Group {
  id: number;
  name: string;
}

interface InviteToGroupFormProps {
  user: User;
  groups: Group[];
  selectedGroup: Group | null;
  onSubmit: (data: { email: string; group: number }, setErrors: React.Dispatch<React.SetStateAction<{ email: string; group: string }>>) => void;
  onClose: () => void;
}

const InviteToGroupForm: React.FC<InviteToGroupFormProps> = ({ user, groups, selectedGroup, onSubmit, onClose }) => {
  const [email, setEmail] = useState<string>(user.email);
  const [group, setGroup] = useState<number | "">(selectedGroup ? selectedGroup.id : "");
  const [errors, setErrors] = useState<{ email: string; group: string }>({ email: "", group: "" });
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedGroup) {
      setGroup(selectedGroup.id);
    }
  }, [selectedGroup]);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    onSubmit({ email, group: group as number }, setErrors);
    onClose(); // Cerrar el modal después de enviar el formulario
  };

  return (
    <Form onSubmit={handleSubmit}>
      {user && <h4>{user.pen_name}</h4>}
      <Form.Group controlId="formGroup">
        <Form.Label>Group</Form.Label>
        {groups.map((grp) => (
          <Form.Check
            type="radio"
            key={grp.id}
            name="group"
            label={grp.name}
            value={grp.id}
            checked={group === grp.id}
            onChange={(e) => setGroup(Number(e.target.value))}
            required
          />
        ))}
      </Form.Group>
      {errors.group && <Alert variant="danger">{errors.group}</Alert>}
      <Form.Control
        type="hidden"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      {errors.email && <Alert variant="danger">{errors.email}</Alert>}
      <Button type="submit" className="btn purple">Invite</Button>
      <Button variant="secondary" onClick={() => setErrors({ email: "", group: "" })} data-dismiss="modal">Cancel</Button>
    </Form>
  );
};

interface InviteUserFromProfileProps {
  userId: number;
}

const defaultUser: User = {
    pk: 0,
    pen_name: "",
    email: ""
};

const InviteUserFromProfile: React.FC<InviteUserFromProfileProps> = ({ userId }) => {
  const [state, fetchData] = useFetch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null);
  const [noGroups, setNoGroups] = useState<boolean>(false);
  const [groups, setGroups] = useState<Group[]>([]);
  const [user, setUser] = useState<User>(defaultUser);
  const authenticationContext = useContext(AuthenticationContext);

  const handleInvite = (group: Group | null) => {
    setSelectedGroup(group);
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleSubmit = async (data: { email: string; group: number }, setErrors: React.Dispatch<React.SetStateAction<{ email: string; group: string }>>) => {
    fetchData({
      url: `group/api/invite/user-from-profile/${userId}/`,
      method: "POST",
      token: authenticationContext.token,
      body: JSON.stringify(data),
    })
      .then(() => {
        toast.success("Your invitation was sent successfully.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .catch((error) => {
        toast.error("Failed to save changes.", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });;
  };

  useEffect(() => {
    fetchData({
      url: `group/api/invite/user-from-profile/${userId}/`,
      method: "GET",
      token: authenticationContext.token,
    }).then((result) => {
      setNoGroups(result.no_groups);
      setGroups(result.groups);
      setUser(result.user);
    }).catch((error) => {
      console.error("Error fetching groups:", error);
    });
  }, [userId]);

  return (
    <>
      <Button onClick={() => handleInvite(null)} style={{marginLeft: "2%"}}>Invite to a group</Button>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>INVITE MEMBER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {noGroups ? (
            <p>You can't invite this writer to a group. This person is either already a member of your group, or you are not a group facilitator.</p>
          ) : (
            <InviteToGroupForm
              user={user}
              groups={groups}
              selectedGroup={selectedGroup}
              onSubmit={handleSubmit}
              onClose={handleClose}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InviteUserFromProfile;