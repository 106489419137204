import React, { useContext, useState, useEffect } from "react";
import AuthenticationContext from "contexts/AuthenticationContext";
import useFetch from "custom-hooks/useFetch";
import { toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";

import './WelcomeNewMembersForm.scss';

import config from "../../config";

const BASE_API_URL = config.BASE_API_URL;

interface WelcomeNewMembersFormProps {
  groupId: number;
}

const WelcomeNewMembersForm: React.FC<WelcomeNewMembersFormProps> = ({ groupId }) => {
  const initialMessage = `Hi and welcome to our group! Please Introduce yourself on the discussion board when you join us!`;
  const [state, fetchData] = useFetch();
  const [welcomeMessage, setWelcomeMessage] = useState(initialMessage);
  const [showCritiqueInWelcome, setShowCritiqueInWelcome] = useState(true);
  const [errors, setErrors] = useState<{ message?: string }>({});
  const authenticationContext = useContext(AuthenticationContext);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const data = { welcomeMessage, showCritiqueInWelcome };

    fetchData({
      url: `group/api/update-welcome/${groupId}/`,
      method: "POST",
      token: authenticationContext.token,
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        toast.success('Welcome message updated successfully');
      })
      .catch((error: any) => {
        if (error.response && error.response.data) {
          setErrors(error.response.data);
          toast.error(error.message);
        } else {
          console.error('Error submitting form:', error);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit} className="ink-welcome-new-members-form">
      <Row>
        <Col md={6} lg={6}>
          <h5 style={{ margin: 0 }}>New member welcome</h5>
          <p><em>(This message will automatically go to new members who accept your invite.)</em></p>
        </Col>
        <Col md={6} lg={6}>
          {errors.message && <div className="error">{errors.message}</div>}
          <div className="textarea-holder">
            <textarea
              value={welcomeMessage}
              onChange={(e) => setWelcomeMessage(e.target.value)}
              placeholder="Enter welcome message"
              className="form-control"
            />
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={6} lg={6}>
          <h5 style={{ margin: 0 }}>Include critique parameters?</h5>
        </Col>
        <Col md={6} lg={6}>
          <div className="checkbox-holder">
            <input
              type="checkbox"
              checked={showCritiqueInWelcome}
              onChange={(e) => setShowCritiqueInWelcome(e.target.checked)}
            />
          </div>
        </Col>
      </Row>
      <div className="button-holder">
        <button type="submit" className="btn purple">Save</button>
      </div>
      <div className="button-holder">
        <a target="_blank" rel="noopener noreferrer" href={`${BASE_API_URL}group/preview_email/${groupId}`}>Preview the email</a>
      </div>
    </form>
  );
};

export default WelcomeNewMembersForm;
