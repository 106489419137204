import { createGlobalState } from 'react-global-state-hooks';
import { FullGroup, Group } from 'types';


type BaseResultsType = {
    started: boolean,
    completed: boolean,
}

export interface GroupState extends BaseResultsType {
    group: FullGroup | null;
}


export const useGroup = createGlobalState<GroupState>({
    started: false,
    completed: false,
    group: null
});
