import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Accordion,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import ReactPlaceholder from "react-placeholder/lib";
import {
  BaseDocument,
  BaseProject,
  BaseSubmission,
  DashboardSubmission,
} from "types";
import Config from "../../../config";
import { Wrapper } from "./CurrentTab";
import "./DashboardComponents.scss";
import DocumentDeleteModal from "./modals/DocumentDelete";
import DocumentDownloadCritiques from "./modals/DocumentDownloadCritiques";
import ActivateModal from "./modals/ProjectActivate";
import AuthenticationContext from "contexts/AuthenticationContext";
import useFetch from "custom-hooks/useFetch";

const backend_url = Config.BASE_API_URL?.slice(0, -1);

type ArchivedProjectRowProps = {
  project: BaseProject;
  open: Boolean;
  onChange: () => void;
};

const ArchivedProjectRow = (props: ArchivedProjectRowProps) => {
  const { project, open, onChange } = props;
  const [showActivateModal, setShowActivateModal] = useState(false);

  return (
    <Row className="project-row">
      <Col
        className="document-title"
        style={{ paddingLeft: "1.9em", cursor: "pointer" }}
        sm={7}
      >
        <span>{open ? "-" : "+"}</span>&nbsp;
        <img
          className="icon-img"
          src={`${backend_url}/static/img/folder.png`}
          alt="folder"
        />
        &nbsp;
        {project.title}
      </Col>
      <Col className="action-buttons" sm={5}>
        <a href="#" onClick={() => setShowActivateModal(true)}>
          <Button>Make Active</Button>
        </a>
      </Col>
      <ActivateModal
        show={showActivateModal}
        onHide={() => {
          setShowActivateModal(false);
        }}
        project={project}
        onChange={onChange}
      />
    </Row>
  );
};

type ArchivedSubmissionRowProps = {
  submission: DashboardSubmission;
  inner: boolean;
};

const ArchivedSubmissionRow = (props: ArchivedSubmissionRowProps) => {
  const { submission, inner } = props;
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <Row
      className={"project-row submission-row" + (inner ? " inner" : "")}
      {...props}
    >
      <Col className="critique" sm={9}>
        <a
          href={`${backend_url}${submission.url}`}
          className="label sm-text btn-sm"
        >
          Critique
        </a>
        &nbsp;
        <span className="italic">{submission.group.name}</span>
      </Col>
      <Col className="action-buttons" style={{ minWidth: 140 }} sm={3}>
        <Button onClick={handleShow}>Download Critique</Button>
      </Col>

      <DocumentDownloadCritiques
        show={showModal}
        onHide={handleClose}
        submission={submission}
      />
    </Row>
  );
};

type ArchivedDocumentRowProps = {
  document: BaseDocument;
  inner: Boolean;
  project: BaseProject;
  onChange: () => void;
};

const ArchivedDocumentRow = (props: ArchivedDocumentRowProps) => {
  const { document, inner, onChange } = props;
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const renderTooltip = (props: any) => (
    <Tooltip
      id={`button-tooltip-${document.id}`}
      {...props}
      className="document-options-tooltip"
    >
      <ul>
        <li>
          <a href={`${backend_url}/document/download/${document.id}/`}>
            Download
          </a>
        </li>{" "}
        {/* Keep as link */}
        <li>
          <a href="#" onClick={() => setShowDeleteModal(true)}>
            Delete
          </a>
        </li>{" "}
        {/* Modal */}
      </ul>
    </Tooltip>
  );

  return (
    <Row
      className={"project-row document-row" + (inner ? " inner" : "")}
      {...props}
    >
      <Col className="document-title" style={{ paddingLeft: "1.9em" }} sm={9}>
        <a className="table-space" href={`${backend_url}${document.url}`}>
          {document.title}&nbsp;
        </a>
        <span className="sm-text">({document.word_count} words)</span>
      </Col>
      <Col className="action-buttons" sm={3}>
        <OverlayTrigger
          placement="bottom-start"
          overlay={renderTooltip}
          trigger="click"
        >
          <Button style={{ zIndex: 100 }} variant="primary">
            Options
          </Button>
        </OverlayTrigger>
      </Col>
      {showDeleteModal && (
        <DocumentDeleteModal
          show={showDeleteModal}
          onHide={() => {
            setShowDeleteModal(false);
          }}
          document={document}
          onChange={onChange}
        />
      )}
    </Row>
  );
};

type ProjectProps = {
  open: Boolean;
  project: BaseProject;
  loading?: Boolean;
  onChange: () => void;
};

const Project = (props: ProjectProps) => {
  const { project, loading, open, onChange } = props;
  return (
    <div>
      <ReactPlaceholder
        type="text"
        rows={10}
        ready={!loading}
        style={{ padding: 20 }}
      >
        <Accordion.Toggle eventKey={project.id.toString()} as={Wrapper}>
          <ArchivedProjectRow
            project={project}
            open={open}
            onChange={onChange}
          />
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={project.id.toString()} as={Wrapper}>
          <>
            {project?.documents?.map((document: any) => (
              <>
                <ArchivedDocumentRow
                  document={document}
                  inner
                  project={project}
                  onChange={onChange}
                />
                {document.submissions?.map((submission: any) => (
                  <ArchivedSubmissionRow submission={submission} inner />
                ))}
              </>
            ))}
          </>
        </Accordion.Collapse>
      </ReactPlaceholder>
    </div>
  );
};

type ArchiveTabProps = {
  lastModified?: number | null;
  onChange: () => void;
};

const ArchiveTab = ({ lastModified, onChange }: ArchiveTabProps) => {
  const [openProjectId, setOpenProjectId] = React.useState(lastModified);
  const [fetchProjectsState, fetchProjects] = useFetch();
  const [projects, setProjects] = useState<BaseProject[] | null>(null);
  const authenticationContext = useContext(AuthenticationContext);

  useEffect(() => {
    loadProjects();
  }, []);

  const loadProjects = () => {
    fetchProjects({
      url: "api/v1.0/dashboard/archived-projects/",
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    }).then((data: BaseProject[]) => {
      setProjects(data);
    });
  };

  const onAccordionChange = (selectedId: any) => {
    setOpenProjectId(selectedId);
  };

  useEffect(() => {
    setOpenProjectId(lastModified);
  }, [lastModified]);

  return (
    <div className="tab-pane archive-tab" id="archive">
      {projects?.length === 0 && (
        <Row>
          <Col className="no-projects">You have no archived projects</Col>
        </Row>
      )}
      {projects?.length && projects?.length > 0 && (
        <ReactPlaceholder
          type="text"
          rows={10}
          ready={fetchProjectsState.isSuccess}
          style={{ padding: 20 }}
        >
          <Accordion
            onSelect={onAccordionChange}
            defaultActiveKey={lastModified?.toString()}
          >
            {projects?.map((project: any) => (
              <Project
                project={project}
                open={openProjectId === project.id}
                onChange={onChange}
              />
            ))}
          </Accordion>
        </ReactPlaceholder>
      )}
    </div>
  );
};

export default ArchiveTab;
