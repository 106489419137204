import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import sendRequest from "services/dataService";
import { DiscussionComment } from "types";
import SubmissionCommentForm from "./SubmissionCommentForm";
import PaginationButtons from "components/pagination-buttons/PaginationButtons";
import SubmissionCommentComponent from "./SubmissionComment";

type SubmissionCommentsProps = {
  submissionId: number;
};

export default function SubmissionComments({
  submissionId,
}: SubmissionCommentsProps) {
  const [comments, setComments] = useState<null | DiscussionComment[]>(null);
  const [totalPages, setTotalPages] = useState<null | number>(null);
  const [currentPage, setCurrentPage] = useState<null | number>(null);
  const authenticationContext = useContext(AuthenticationContext);

  const fetchComments = () => {
    const PAGE_SIZE = 10;
    const url = `api/v1.0/submission/${submissionId}/comments/?page=${
      currentPage || 1
    }`;
    sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    }).then((response) => {
      setComments(response.results);
      setTotalPages(Math.ceil(response.count / PAGE_SIZE));
    });
  };

  useEffect(() => {
    fetchComments();
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, []);

  return (
    <div className="pb-4">
      <Row>
        <Col xs={10}>
          <h1>
            <h1>Q&amp;A DISCUSSION</h1>
            <SubmissionCommentForm
              submissionId={submissionId}
              onPostComment={() => {
                setCurrentPage(null);
                setCurrentPage(1);
              }}
            />
          </h1>
        </Col>
      </Row>

      {comments?.length === 0 && (
        <Row>
          <p>No comments have been posted yet.</p>
        </Row>
      )}
      {comments !== null && (
        <Row>
          <Col xs={10} className="pl-8">
            {comments?.map((comment: DiscussionComment) => (
              <SubmissionCommentComponent
                key={comment.id}
                comment={comment}
                onReply={() => {
                  fetchComments();
                }}
              />
            ))}
          </Col>
        </Row>
      )}
      {currentPage != null && totalPages != null && totalPages > 1 && (
        <div className="pagination">
          <PaginationButtons
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={(page) => setCurrentPage(page)}
            firstPageLabel="Newest"
            lastPageLabel="Oldest"
          />
        </div>
      )}
    </div>
  );
}
