import sendRequest from "services/dataService";


export const loadGroupData = async (token: string, groupId: number) => {
  const response = await sendRequest({
    url: `api/v1.0/group/${groupId}`,
    method: "GET",
    token: token,
    body: null,
  });
  return response;
};
