import config from "config";
import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import sendRequest from "services/dataService";
import { BaseUser, CurrentUserState } from "types";
import "../edit/EditCritique.scss";
import "./AllCritiques.scss";
import {
  AnnotatedContent,
  SubmissionResponse,
  CritiqueResponse,
} from "../commons";
import DropdownMultiselect from "components/dropdown-multiselect/DropdownMultiselect";
import { displayDate } from "pages/events/utils";
import SubmissionComments from "components/critique/submission-comments/SubmissionComments";
import { sub } from "date-fns";
import DocumentDownloadCritiques from "pages/dashboard/components/modals/DocumentDownloadCritiques";

const CritiqueCard = ({ critique }: { critique: CritiqueResponse }) => {
  return (
    <div className="critique-card">
      {critique.author?.image && (
        <img
          className="critique-card-image"
          src={critique.author.image}
          alt={critique.author.pen_name}
        />
      )}
      <h3>
        <Link to={`/profile/view/${critique.author.id}`}>
          {critique.author.pen_name}
        </Link>
      </h3>
      <span className="date">
        {displayDate(critique.finished_at)},{" "}
        {new Date(critique.finished_at).getFullYear()}
      </span>
      <p
        dangerouslySetInnerHTML={{ __html: critique.contents }}
        style={{ paddingTop: "10px" }}
      />
    </div>
  );
};

export default function AllCritiques() {
  const authenticationContext = useContext(AuthenticationContext);
  const { id } = useParams() as any;
  const [submission, setSubmission] = useState<SubmissionResponse | undefined>(
    undefined
  );
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const [critiques, setCritiques] = useState<CritiqueResponse[] | undefined>(
    undefined
  );
  const [allUsers, setAllUsers] = useState<BaseUser[] | undefined>(undefined);
  const [selectedEditors, setSelectedEditors] = useState<
    BaseUser[] | undefined
  >(undefined);
  const selectedCritiques = critiques?.filter((c) =>
    selectedEditors?.includes(c.author)
  );
  const [downloadCritiqueModalOpen, setDownloadCritiqueModalOpen] =
    useState<boolean>(false);

  async function loadSubmission() {
    const url = `${config.BASE_API_URL}api/v1.0/submission/${id}/`;
    const response: SubmissionResponse | undefined = await sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    });
    setSubmission(response);
  }

  async function loadCritiques() {
    const url = `${config.BASE_API_URL}api/v1.0/submission/${id}/critique/all/`;
    const response: CritiqueResponse[] = await sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    });
    setCritiques(response);
    const users = response.map((critique) => critique.author);
    setAllUsers(users);
    setSelectedEditors(users);
  }

  useEffect(() => {
    if (authenticationContext.token && id) {
      loadSubmission();
      loadCritiques();
    }
  }, [id, authenticationContext]);
  const slug = submission?.group.name.replace(/\s+/g, "-").toLowerCase();

  return (
    <>
      <Container style={{ marginBottom: 0, overflowY: "hidden" }}>
        <Row style={{ fontSize: 15 }}>
          <Col xs={12} className="mb-2">
            <Link
              to={`/group/${submission?.group.id}/${slug}/`}
              id="returntogroup"
            >
              {" "}
              Return to {submission?.group.name}
            </Link>
          </Col>
          {submission?.group.is_admin && (
            <Col xs={12} className="mb-2">
              <Link
                to={`/critique/manage-critiques/${submission?.group.id}/`}
                id="returntogroup"
              >
                Return to manage critiques
              </Link>
            </Col>
          )}
        </Row>

        <Row>
          <Col
            xs={12}
            md={8}
            className="justify-between items-start p-4 pb-2 border-l border-r border-t"
          >
            <Row>
              <Col xs={12} md={8}>
                <h4 style={{ textTransform: "capitalize", fontSize: "22px" }}>
                  {submission?.document.title}
                </h4>
                <h5 className="text-gray-500">
                  {submission?.document.author.pen_name}
                </h5>
              </Col>
              <Col xs={12} md={4}>
                {submission &&
                  userState.data?.id > 0 &&
                  (submission?.document.author.id === userState.data?.id ? (
                    <>
                      <a
                        href="#"
                        className="float-right hover-underline"
                        style={{
                          fontSize: "15px",
                          marginTop: 15,
                          marginRight: 10,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setDownloadCritiqueModalOpen(true);
                        }}
                      >
                        Download Critique
                      </a>
                      <DocumentDownloadCritiques
                        show={downloadCritiqueModalOpen}
                        onHide={() => setDownloadCritiqueModalOpen(false)}
                        submission={{
                          ...submission,
                          id,
                          finished_critiques:
                            critiques?.map((c) => {
                              return {
                                author_name: c.author.pen_name,
                                author_id: c.author.id,
                              };
                            }) || [],
                        }}
                      />
                    </>
                  ) : (
                    <a
                      href={`${config.BASE_API_URL}document_to_pdf/${submission?.document.id}/?print=pdf`}
                      style={{ float: "right" }}
                    >
                      <div
                        style={{
                          width: 40,
                          height: 33,
                          background: `url(${config.BASE_API_URL}static/img/sprite.png) -500px -200px`,
                        }}
                      />
                    </a>
                  ))}
              </Col>
            </Row>
            <div className="text-right">{/* DISPLAY PRINT PREVIEW */}</div>
          </Col>
          <Col xs={0} md={4}>
            {allUsers && allUsers.length > 0 && (
              <div
                className="pl-4 bg-white pb-2"
                style={{ zIndex: 99, position: "relative" }}
              >
                <h4 className="mb-2">Editors</h4>
                <DropdownMultiselect
                  options={allUsers?.map((u) => {
                    return { key: u.id, label: u.pen_name };
                  })}
                  selected={selectedEditors?.map((u) => u.id) || []}
                  onChange={(values) => {
                    setSelectedEditors(
                      allUsers?.filter((u) => values.includes(u.id))
                    );
                  }}
                />
              </div>
            )}
          </Col>
        </Row>
        <AnnotatedContent
          submissionId={id}
          documentId={submission?.document.id || 0}
          content={submission?.document.contents}
          userData={userState.data}
          notes={submission?.notes}
          all={true}
          users={selectedEditors}
          canDelete={false}
        />
        <Row>
          <Col
            xs={12}
            className="justify-between items-start pb-8 pt-4 bg-white"
            style={{ zIndex: 99, paddingLeft: 0 }}
          >
            {allUsers && allUsers.length > 0 && (
              <div>
                <h2>Overall Feedback</h2>
                {selectedCritiques?.map((critique) => (
                  <CritiqueCard key={critique.id} critique={critique} />
                ))}
              </div>
            )}
          </Col>
        </Row>
        {!submission?.can_critique && <SubmissionComments submissionId={id} />}
      </Container>
    </>
  );
}
