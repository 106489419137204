import React, { useContext, useState } from "react";
import AuthenticationContext from "contexts/AuthenticationContext";
import { Col, Row } from "react-bootstrap";
import useFetch from "custom-hooks/useFetch";
import { toast } from "react-toastify";

import './InviteByEmailsForm.scss';

interface InviteFormProps {
  groupId: number;
}

const InviteByEmailsForm: React.FC<InviteFormProps> = ({ groupId }) => {
  const [state, fetchData] = useFetch();
  const [emails, setEmails] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState<{ emails?: string; message?: string }>({});
  const authenticationContext = useContext(AuthenticationContext);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const data = {
      emails,
      message,
      "group": groupId,
    };

    fetchData({
      url: `group/api/invite/${groupId}/`,
      method: "POST",
      token: authenticationContext.token,
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        // Reset form or show success message
        setEmails('');
        setMessage('');
        toast.success('Invitation sent successfully!');
      })
      .catch((error: any) => {
        if (error.response && error.response.data) {
          setErrors(error.response.data);
          toast.error(error.message);
        } else {
          console.error('Error submitting form:', error);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit} className="ink-invite-form">
      <Row>
        <Col md={6}>
          <h5 style={{ margin: 0 }}>Invite a person outside the site</h5>
          <p><em>(separate multiple names with a comma)</em></p>
        </Col>
        <Col md={6}>
          {errors.emails && <div className="error">{errors.emails}</div>}
          <div className="input-holder">
            <input
              type="text"
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
              placeholder="[Type the email address]"
              className="form-control"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}></Col>
        <Col md={6}>
          {errors.message && <div className="error">{errors.message}</div>}
          <div className="textarea-holder">
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="[Send a message with the invitation]"
              className="form-control"
            />
          </div>
          <div className="button-holder">
            <button type="submit" className="btn purple">Submit</button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default InviteByEmailsForm;