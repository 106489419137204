import AuthenticationContext from "contexts/AuthenticationContext";
import { createRef, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import sendRequest from "services/dataService";
import {
  IVTable,
  IVTableBody,
  IVTableHead,
  IVTd,
  IVTr,
} from "./groupDetailsTabTable";
import { useSelector } from "react-redux";
import { CurrentUserState } from "types";
import DeleteFileModal from "./deleteFileModal";
import { displayCondensedDate } from "pages/events/utils";

type GroupDetailsFilesTabProps = {
  groupId: number;
};

export default function GroupDetailsFilesTab({
  groupId,
}: GroupDetailsFilesTabProps) {
  const inputRef = createRef<HTMLInputElement>();
  const authenticationContext = useContext(AuthenticationContext);
  const [groupFiles, setGroupFiles] = useState<any[]>([]);
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const [fileDeleting, setFileDeleting] = useState<{
    id: number;
    name: string;
  } | null>(null);

  const handleFileUpload = (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];
    const expectedExtension = ["docx", "pdf", "png", "jpg", "jpeg"];
    const maxFileSize = 15728640;
    if (!expectedExtension.includes(file.name.split(".").at(-1))) {
      alert("Unexpected file format");
      return;
    }
    if (file.size > maxFileSize) {
      alert("File size exceeds 15 MB");
      return;
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const base64File = reader.result;
      const url = `api/v1.0/group/${groupId}/files/`;
      sendRequest({
        url,
        method: "POST",
        token: authenticationContext.token || "",
        body: JSON.stringify({ file: base64File, name: file.name }),
      }).then((response) => {
        fetchGroupFiles(groupId);
      });
    };
  };

  const handleDeleteFile = (file: { id: number; name: string }) => {
    setFileDeleting(file);
  };

  const fetchGroupFiles = (groupId: number) => {
    const url = `api/v1.0/group/${groupId}/files/`;
    sendRequest({
      url,
      method: "GET",
      token: authenticationContext.token || "",
      body: null,
    }).then((response) => {
      setGroupFiles(response.results);
    });
  };

  useEffect(() => {
    fetchGroupFiles(groupId);
  }, [groupId]);

  return (
    <>
      <Row className="w-full">
        <Col sm={12} md={10}>
          <p className="text-sm mt-2">
            Share your manuscript synopsis, helpful resources, conference notes,
            illustrations, etc. here. <br /> This section is for file-sharing
            but not critique. Accepted formats: .docx, .pdf, .png, .jpg.
          </p>
        </Col>
        <Col sm={12} md={2}>
          <a
            className="btn green text-sm float-right mt-2 btn-small"
            onClick={() => inputRef.current?.click()}
          >
            Upload a file
          </a>
          <input
            type="file"
            style={{ display: "none" }}
            ref={inputRef}
            onChange={handleFileUpload}
          />
        </Col>
      </Row>
      <Row>
        <IVTable>
          <IVTableHead>
            <IVTr>
              <th scope="col">Date</th>
              <th scope="col">Name</th>
              <th scope="col">Owner</th>
              <th scope="col" style={{ width: "10%" }}></th>
            </IVTr>
          </IVTableHead>
          <IVTableBody>
            {groupFiles?.map((file: any) => (
              <IVTr>
                <IVTd>{displayCondensedDate(file.upload_date)}</IVTd>
                <IVTd>
                  <a
                    href={file.file}
                    target="_blank"
                    style={{ color: "black !important" }}
                  >
                    {file.name}
                  </a>
                </IVTd>
                <IVTd>{file.owner.pen_name}</IVTd>
                <IVTd>
                  {file.owner.id === userState.data.id && (
                    <a href="#" onClick={() => handleDeleteFile(file)}>
                      Delete
                    </a>
                  )}
                </IVTd>
              </IVTr>
            ))}
          </IVTableBody>
        </IVTable>
      </Row>
      {fileDeleting && (
        <DeleteFileModal
          file={fileDeleting}
          groupId={groupId}
          show={Boolean(fileDeleting)}
          onHide={(res) => {
            setFileDeleting(null);
            if (res) {
              fetchGroupFiles(groupId);
            }
          }}
        />
      )}
    </>
  );
}
