import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";

export default function EditCritiqueModal({
  submission,
  show,
  onHide,
}: {
  submission: { id: number; title: string };
  show: Boolean;
  onHide: (deleted: Boolean) => void;
}) {
  const history = useHistory();
  const authenticationContext = useContext(AuthenticationContext);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await sendRequest({
        url: `api/v1.0/submission/${submission.id}/critique/reopen/`,
        method: "PUT",
        token: authenticationContext.token || "",
        body: null,
      });
      onHide(true);
      toast.success("Critique reopened.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      history.push(`/critique/edit/${submission.id}/?reopen=true`);
    } catch (error: any) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <Modal show={show} centered onHide={() => onHide(false)}>
      <Modal.Header>
        <Modal.Title>Reminder: Re-Submit After Editing</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Opening your critique to edit returns it to an in-process state. You
        must re-submit, even if you do not make changes.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="primary purple" onClick={onSubmit}>
          Edit critique
        </Button>
        <Button
          variant="secondary"
          className="secondary grey"
          onClick={() => onHide(false)}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
