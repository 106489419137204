import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";
import { SubmissionInCritique } from "types";
import "react-datepicker/dist/react-datepicker.css";
import { getTimezone } from "pages/events/utils";

export default function EditDueDateModal({
  submission,
  show,
  onHide,
}: {
  submission: SubmissionInCritique;
  show: Boolean;
  onHide: (deleted: Boolean) => void;
}) {
  const authenticationContext = useContext(AuthenticationContext);
  const [date, setDate] = useState<Date | null>(
    submission.due_date ? new Date(submission.due_date) : null
  );

  const onSubmit = async (e: any) => {
    e.preventDefault();
    try {
      await sendRequest({
        method: "PATCH",
        url: `api/v1.0/submission/${submission.id}/`,
        token: authenticationContext.token || "",
        body: JSON.stringify({ due_date: date?.toISOString() }),
      });
      onHide(true);
      toast.success("Due date updated successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } catch (error: any) {
      toast.error(error.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  return (
    <Modal show={show} centered onHide={() => onHide(false)}>
      <form onSubmit={onSubmit}>
        <Modal.Header>
          <Modal.Title>Edit Due Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={4} className="p-0">
              <DatePicker
                onChange={(newVal: Date | null) => {
                  setDate(newVal);
                }}
                selected={date}
                showTimeInput
                dateFormat="d/MMM yy HH:mm"
                className="due-date-picker"
                // customTimeInput={<ExampleCustomTimeInput />}
              />
            </Col>
            <Col className="p-0">({getTimezone()})</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="primary purple" type="submit">
            Save
          </Button>
          <Button
            variant="secondary"
            className="secondary grey"
            onClick={() => onHide(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
