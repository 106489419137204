import React, { useEffect, useContext } from "react";
import AuthenticationContext from "contexts/AuthenticationContext";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Loading from "components/loading/Loading";
import GroupEditProfileForm from "./components/GroupEditProfileForm";
import { loadGroupData } from "services/groupDataService";
import { useGroup } from "global-state/group";


const GroupEditProfile = () => {
  const { groupId } = useParams() as any;
  const authenticationContext = useContext(AuthenticationContext);
  const user = useSelector((state: any) => state.user);
  const [groupProfile, setGroupProfile] = useGroup();

  async function fetchGroupProfileData() {
    if (authenticationContext.token){
      // TODO: Remove global state and use local state
      setGroupProfile({
          started: false,
          completed: false,
          group: null
      });
      const response = await loadGroupData(authenticationContext.token, groupId);
      setGroupProfile({
        started: true,
        completed: true,
        group: response,
      });
    }
  }

  useEffect(() => {
    fetchGroupProfileData();
  }, [authenticationContext]);

  return (groupProfile.group) ? <GroupEditProfileForm groupData={groupProfile.group} /> : <Loading></Loading>
};

export default GroupEditProfile;
