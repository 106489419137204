import { useState } from "react";
import {
  IVTable,
  IVTableBody,
  IVTableHead,
  IVTr,
  IVTd,
  IVTableSeparator,
} from "./groupDetailsTabTable";
import { useSelector } from "react-redux";
import { CurrentUserState, SubmissionInCritique } from "types";
import { displayCondensedDate, displayShortTime } from "pages/events/utils";
import DeleteSubmissionModal from "./deleteSubmissionModal";
import EditCritiqueModal from "./editCritiqueModal";
import MoveToReviewModal from "./moveToReviewModal";
import EditDueDateModal from "./groupDetailEditDueDate";
import config from "config";

const SubmissionCritiqueStatus = ({ submission }: { submission: any }) => {
  const status = submission.status_for_user;
  const [showEditCritiqueModal, setShowEditCritiqueModal] = useState(false);
  if (status === "Read Critiques") {
    return (
      <a
        className="btn submission green"
        href={`/critique/all_for/${submission.id}/Submission/`}
        role="button"
      >
        {status}
      </a>
    );
  }
  if (status === "Give Critique" || status === "Done") {
    if (submission.user_finished_critique || status === "Done") {
      return (
        <>
          <a
            className="submission"
            href="#"
            role="button"
            onClick={() => setShowEditCritiqueModal(true)}
          >
            Edit Critique
          </a>
          <EditCritiqueModal
            submission={submission}
            show={showEditCritiqueModal}
            onHide={() => {
              setShowEditCritiqueModal(false);
            }}
          />
        </>
      );
    }
    return (
      <a
        className="submission btn blue"
        href={`/critique/edit/${submission.id}/`}
        role="button"
      >
        {status}
      </a>
    );
  }
  if (status === "Finish Critique") {
    return (
      <a
        className="submission btn purple"
        href={`/critique/edit/${submission.id}/`}
        role="button"
      >
        {status}
      </a>
    );
  }
  if (status === "Submitted") {
    return (
      <a
        className="btn-default submission"
        href={`/critique/all_for/${submission.id}/Submission/`}
        role="button"
      >
        {status}
      </a>
    );
  }

  return <>{submission.status_for_user}</>;
};

const SubmissionInCritiqueTab = ({
  submission,
  onDelete,
  onEdit,
  onMoveToReview,
}: {
  submission: SubmissionInCritique;
  onDelete: (submission: SubmissionInCritique) => void;
  onEdit: (submission: SubmissionInCritique) => void;
  onMoveToReview: (submission: SubmissionInCritique) => void;
}) => {
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const status = submission.status_for_user;
  const finished = submission.user_finished_critique;
  const critiquing =
    (status === "Give Critique" && !finished) || status === "Finish Critique";

  return (
    <IVTr>
      <IVTd>
        <div>
          {displayCondensedDate(submission.due_date).slice(0, -3)}
          <br />
          {displayShortTime(submission.due_date)}
          <br />
          <a
            role="button"
            data-target="#myModal5"
            data-toggle="modal"
            onClick={() => onEdit(submission)}
          >
            Edit
          </a>
        </div>
      </IVTd>

      <IVTd>
        <a
          className="cutoff text-center"
          href={
            userState.data.id !== submission.author.id
              ? `/critique/edit/${submission.id}/`
              : `/critique/all_for/${submission.id}/Submission/`
          }
        >
          {submission.title}
        </a>
      </IVTd>

      <IVTd className="text-center">
        <a href={`http://localhost:3000/profile/view/${submission.author.id}`}>
          {userState.data.id === submission.author.id
            ? "Me"
            : submission.author.pen_name}
        </a>
      </IVTd>

      <IVTd className="text-center">
        <SubmissionCritiqueStatus submission={submission} />
        {submission.is_being_critiqued_by_anyone &&
          userState.data.id !== submission.author.id && (
            <>
              <img
                src={config.BASE_API_URL + "static/img/glasses.png"}
                width={24}
                height={24}
                style={{ marginLeft: "10px" }}
              />
            </>
          )}
      </IVTd>

      <IVTd className="text-center" style={{ textAlign: "center" }}>
        {submission.finished_critiques_count}
      </IVTd>

      <IVTd className="text-center">
        {userState.data.id === submission.author.id &&
          submission.can_be_removed && (
            <>
              <div className="text-center">
                <a href="#" onClick={() => onDelete(submission)}>
                  Remove
                </a>
              </div>
              <div className="text-center">
                <a href="#" onClick={() => onMoveToReview(submission)}>
                  Move to Review
                </a>
              </div>
            </>
          )}
      </IVTd>
    </IVTr>
  );
};

type GroupDetailCritiqueTabProps = {
  submissions: SubmissionInCritique[] | null;
  onChange?: () => void;
};

export default function GroupDetailCritiqueTab({
  submissions,
  onChange,
}: GroupDetailCritiqueTabProps) {
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const [submissionToDelete, setSubmissionToDelete] =
    useState<SubmissionInCritique | null>(null);

  const [submissionToEdit, setSubmissionToEdit] =
    useState<SubmissionInCritique | null>(null);

  const [submissionToMoveToReview, setSubmissionToMoveToReview] =
    useState<SubmissionInCritique | null>(null);

  return (
    <div className="tab-pane active current-tab" id="current">
      <IVTable>
        <IVTableHead>
          <IVTr>
            <th scope="col" style={{ width: "60px" }} className="text-center">
              Due
            </th>
            <th scope="col" className="text-center">
              Title
            </th>
            <th scope="col" className="text-center" style={{ width: "150px" }}>
              Author
            </th>
            <th scope="col" style={{ width: "160px" }} className="text-center">
              Status
            </th>
            <th scope="col" className="text-center" style={{ width: "60px" }}>
              Critiques
            </th>
            <th scope="col" className="text-center" style={{ width: "120px" }}>
              More
            </th>
          </IVTr>
        </IVTableHead>
        <IVTableBody>
          {submissions
            ?.filter((submission) => submission.author.id === userState.data.id)
            ?.map((submission) => (
              <SubmissionInCritiqueTab
                key={submission.id}
                submission={submission}
                onDelete={setSubmissionToDelete}
                onEdit={setSubmissionToEdit}
                onMoveToReview={setSubmissionToMoveToReview}
              />
            ))}

          <IVTableSeparator cols={6} />

          {submissions
            ?.filter((submission) => submission.author.id != userState.data.id)
            ?.map((submission) => (
              <SubmissionInCritiqueTab
                key={submission.id}
                submission={submission}
                onDelete={setSubmissionToDelete}
                onEdit={setSubmissionToEdit}
                onMoveToReview={setSubmissionToMoveToReview}
              />
            ))}
        </IVTableBody>
      </IVTable>
      {submissionToDelete && (
        <DeleteSubmissionModal
          submission={submissionToDelete}
          show={true}
          onHide={(deleted) => {
            setSubmissionToDelete(null);
            if (deleted) {
              onChange && onChange();
            }
          }}
        />
      )}
      {submissionToMoveToReview && (
        <MoveToReviewModal
          submission={submissionToMoveToReview}
          show={true}
          onHide={(moved) => {
            setSubmissionToMoveToReview(null);
            if (moved) {
              onChange && onChange();
            }
          }}
        />
      )}
      {submissionToEdit && (
        <EditDueDateModal
          submission={submissionToEdit}
          show={true}
          onHide={(edited) => {
            setSubmissionToEdit(null);
            if (edited) {
              onChange && onChange();
            }
          }}
        />
      )}
    </div>
  );
}
