import React, { useContext, useState, useEffect } from "react";
import AuthenticationContext from "contexts/AuthenticationContext";
import useFetch from "custom-hooks/useFetch";
import { toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";

import './MessageToGroupForm.scss';

interface InviteFormProps {
  groupId: number;
}

const MessageToGroupForm: React.FC<InviteFormProps> = ({ groupId }) => {
  const [state, fetchData] = useFetch();
  const [body, setBody] = useState('');
  const [errors, setErrors] = useState<{ body?: string }>({});
  const authenticationContext = useContext(AuthenticationContext);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const data = { body };

    fetchData({
      url: `group/api/message-to-group/${groupId}/`,
      method: "POST",
      token: authenticationContext.token,
      body: JSON.stringify(data),
    })
      .then((response: any) => {
        // Reset form or show success message
        setBody('');
        toast.success('Message sent successfully!');
      })
      .catch((error: any) => {
        if (error.response && error.response.data) {
          setErrors(error.response.data);
          toast.error(error.message);
        } else {
          console.error('Error submitting form:', error);
        }
      });
  };

  return (
    <form onSubmit={handleSubmit} className="ink-message-to-group">
      <Row>
        <Col md={12}>
          {errors.body && <div className="error">{errors.body}</div>}
          <div className="textarea-holder">
            <textarea
              value={body}
              onChange={(e) => setBody(e.target.value)}
              placeholder="[Insert Message Here]"
              className="form-control"
            />
          </div>
          <div className="button-holder">
            <button type="submit" className="btn purple">Submit</button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default MessageToGroupForm;