import AuthenticationContext from "contexts/AuthenticationContext";
import { displayCondensedDate } from "pages/events/utils";
import { useContext, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { CurrentUserState, DiscussionComment } from "types";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import config from "config";
import SubmissionCommentForm from "./SubmissionCommentForm";

type SubmissionCommentComponentProps = {
  comment: DiscussionComment;
  parent?: DiscussionComment;
  isAdmin?: boolean;
  expandByDefault?: boolean;
  onReply: () => void;
};

export default function SubmissionCommentComponent({
  comment,
  parent,
  isAdmin,
  expandByDefault = true,
  onReply,
}: SubmissionCommentComponentProps) {
  const hasReplies = comment.replies?.length > 0;
  const [showReplies, setShowReplies] = useState(expandByDefault);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const authenticationContext = useContext(AuthenticationContext);
  const userState = useSelector(
    (state: { user: CurrentUserState }) => state.user
  );
  const maxReplyLevel = 2;

  return (
    <>
      <div
        className="mb-2"
        style={
          parent ? { paddingRight: 0, minWidth: "100%", marginRight: 0 } : {}
        }
      >
        <span
          className="text-xs"
          style={{
            width: 68,
            height: 60,
            textAlign: "center",
            maxWidth: "68px",
            margin: 0,
            padding: 0,
            float: "left",
          }}
        >
          <p
            style={{
              margin: 0,
              padding: 0,
              lineHeight: "60px",
              fontSize: "9px",
              fontWeight: "bold",
              width: 68,
            }}
          >
            <img src={comment.user.avatar} />
          </p>
        </span>
        <span className="p-0">
          <div className="">
            <Row>
              <Col style={{ fontSize: "13px", lineHeight: "24px" }}>
                <h3>
                  <Link
                    to={`/profile/view/${comment.user.id}`}
                    style={{ fontWeight: "bold" }}
                  >
                    {comment.user.pen_name}
                  </Link>
                </h3>
                {comment.text}{" "}
                {comment.level < maxReplyLevel && (
                  <div>
                    <a
                      href="#"
                      className="font-bold"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowReplyForm(!showReplyForm);
                      }}
                      style={{ fontSize: "15px" }}
                    >
                      Reply
                    </a>
                  </div>
                )}
              </Col>
            </Row>
            {showReplyForm && (
              <>
                <Row style={{ marginTop: 8 }}>
                  <SubmissionCommentForm
                    submissionId={comment.target_instance_id}
                    parentId={comment.id}
                    onPostComment={() => {
                      setShowReplyForm(false);
                      setShowReplies(true);
                      onReply();
                    }}
                  />
                </Row>
              </>
            )}
          </div>
        </span>
      </div>
      {hasReplies && showReplies && (
        <>
          <div style={{ paddingLeft: "80px", paddingRight: 0, width: "100%" }}>
            {comment.replies.map((reply) => (
              <SubmissionCommentComponent
                key={reply.id}
                comment={reply}
                parent={comment}
                isAdmin={isAdmin}
                expandByDefault={expandByDefault}
                onReply={onReply}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
}
