import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactPlaceholder from "react-placeholder";
import { GroupSubmissionSchedule } from "types";

type GroupDetailsSubmissionsTabProps = {
  groupSubmissionSchedule: GroupSubmissionSchedule | null;
};

export default function GroupDetailsSubmissionsTab({
  groupSubmissionSchedule,
}: GroupDetailsSubmissionsTabProps) {
  const [showSettings, setShowSettings] = useState(true);

  return (
    <Row className="mt-4">
      <Col sm={6} md={6}>
        <h5>Submission Schedule</h5>
        <ReactPlaceholder
          type="text"
          rows={3}
          ready={!!groupSubmissionSchedule}
        >
          <p
            style={{
              fontSize: "13px",
              lineHeight: "27px",
              paddingLeft: "20px",
              paddingBottom: "10px",
            }}
            dangerouslySetInnerHTML={{ __html: groupSubmissionSchedule?.submission_schedule || "" }}
          ></p>
        </ReactPlaceholder>

        <ReactPlaceholder
          type="text"
          rows={3}
          ready={!!groupSubmissionSchedule}
        >
          {groupSubmissionSchedule?.inactivity_policy && (
            <>
              <h5>Inactivity Policy</h5>
              <p
                style={{
                  fontSize: "13px",
                  lineHeight: "27px",
                  paddingLeft: "20px",
                  paddingBottom: "10px",
                }}
              >
                {groupSubmissionSchedule?.inactivity_policy}
              </p>
            </>
          )}
        </ReactPlaceholder>
      </Col>
      <Col sm={6} md={6}>
        <a href="#" onClick={() => setShowSettings(!showSettings)}>
          <h5 className="mb-2 hover-underline">
            Submission Settings
            <span
              id="settings-icon"
              className={showSettings ? "icon-open" : "icon-close"}
            ></span>
          </h5>
        </a>
        {showSettings && (
          <div id="settings" className="">
            <ul>
              <li className="link-top">
                <strong>Sexual content:</strong>{" "}
                {groupSubmissionSchedule?.sexual_content_display}
                <a
                  href="#"
                  className="ico-help bot"
                  title="What level of sexual content is acceptable in submissions to the group?<br>
                                    Minimal – Nothing more than kissing.<br>
                                    Mild – May or may not include lovemaking. Sex is implied rather than explicit.<br>
                                    Hot–Ranges from lovemaking to explicit sex. Most romance novels fall into this category.<br>
                                    Scorcher – Borders on erotic. Very graphic sex."
                  data-placement="right"
                ></a>
              </li>
              <li>
                <strong>Violent content:</strong>{" "}
                {groupSubmissionSchedule?.violence_display}{" "}
                <a
                  href="#"
                  className="ico-help"
                  title="What level of violence is acceptable in submissions to the group?<br>
                                    None<br>
                                    Minor- little or brief violence<br>
                                    Mild- several scenes or scenes of longer duration<br>
                                    Significant- descriptive violence throughout<br>
                                    Heavy – violence is intense or gory"
                  data-placement="right"
                ></a>
              </li>
              <li>
                <strong>Word count max:</strong>{" "}
                {groupSubmissionSchedule?.max_words || "N/A"}{" "}
                <a
                  href="#"
                  className="ico-help"
                  title="The max word count is the maximum number of words for a submission."
                  data-placement="right"
                ></a>
              </li>
              <li>
                <strong>Critique ratio:</strong>{" "}
                {groupSubmissionSchedule?.critique_estimate}{" "}
                <a
                  href="#"
                  className="ico-help"
                  title="The critique ratio is the number of credits group members use to submit their work (each critique given earns 1 credit)."
                  data-placement="right"
                ></a>
              </li>
              <li>
                <strong>Critique window:</strong>{" "}
                {groupSubmissionSchedule?.critique_time_window} days{" "}
                <a
                  href="#"
                  className="ico-help"
                  title="The critique window is the number of days group members have to critique submissions."
                  data-placement="right"
                ></a>
              </li>
              <li>
                <strong>Review window:</strong>{" "}
                {groupSubmissionSchedule?.review_time_window} days{" "}
                <a
                  href="#"
                  className="ico-help"
                  title="The review window is the number of days group members can review critiques and participate in critique Q&A. It begins after the critique period ends."
                  data-placement="right"
                ></a>
              </li>
            </ul>
          </div>
        )}
      </Col>
    </Row>
  );
}
