import AuthenticationContext from "contexts/AuthenticationContext";
import { useContext } from "react";
import { toast } from "react-toastify";
import sendRequest from "services/dataService";
import { Group } from "types";

const NewMessageForm = ({
  groupId,
  parentId,
  onPostMessage,
}: {
  groupId: number;
  parentId?: number;
  onPostMessage: () => void;
}) => {
  const authenticationContext = useContext(AuthenticationContext);
  const onMessagePost = (e: React.FormEvent) => {
    e.preventDefault();
    const textArea = (e.currentTarget as any)[0] as HTMLTextAreaElement;
    const text = textArea.value;
    const token = authenticationContext.token || "";
    sendRequest({
      method: "POST",
      url: `api/v1.0/group/${groupId}/comments/`,
      body: JSON.stringify({ text: text, parent: parentId }),
      token,
    }).then((response) => {
      textArea.value = "";
      toast.success("Comment posted", {
        position: "bottom-right",
      });
      onPostMessage();
    });
  };
  return (
    <form onSubmit={onMessagePost}>
      <div className="textarea-holder">
        <textarea
          name="text"
          cols={40}
          rows={2}
          style={{ height: 30 }}
          maxLength={5000}
          placeholder="Ask a question, answer one or make a comment."
          required
          id="id_text"
        />
      </div>
      <p className="submit">
        <input
          type="submit"
          name="post"
          className="submit-post btn"
          style={{
            fontSize: parentId ? "12px" : "16px",
            paddingRight: parentId ? "7px" : "30px",
            paddingLeft: parentId ? "7px" : "30px",
          }}
          value="Post"
        />

        <span className="last-sync"></span>
      </p>
    </form>
  );
};

export default NewMessageForm;
