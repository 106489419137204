import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import "./DropdownMultiselect.scss";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";

type Option = {
  label: string;
  key: number | string;
};

type DropdownMultiselectProps = {
  options: Option[] | undefined;
  selected: (number | string)[];
  onChange: (selected: (number | string)[]) => void;
};

type DropDownOptionComponentProps = {
  key: number | string;
  label: string;
  isSelected: boolean;
  bold?: boolean;
  onClick: () => void;
};

const DropDownOptionComponent = ({
  key,
  label,
  isSelected,
  bold,
  onClick,
}: DropDownOptionComponentProps) => {
  return (
    <li
      key={key}
      onClick={onClick}
      style={{ backgroundColor: isSelected ? "#cfcfcf" : "#fff" }}
    >
      <input type="checkbox" checked={isSelected} onChange={() => {}} />
      <span
        style={{
          fontWeight: bold ? "bold" : 400,
          color: isSelected ? "#fff" : "#000",
        }}
      >
        {label}
      </span>
    </li>
  );
};

export default function DropdownMultiselect({
  options,
  selected,
  onChange,
}: DropdownMultiselectProps) {
  const [open, setOpen] = useState(false);
  const selectedLabels =
    options?.filter((o) => selected.includes(o.key)).map((o) => o.label) || [];
  const title =
    selectedLabels.length > 0
      ? `${selectedLabels.length} selected`
      : "None selected";

  const allSelected = selected.length === options?.length;

  return (
    <div className="dropdown-multiselect-wrapper">
      <Button
        className={"dropdown-multiselect-button" + (open ? " open" : "")}
        onClick={() => setOpen(!open)}
      >
        {title}{" "}
        {open ? <CaretUpFill width={10} /> : <CaretDownFill width={10} />}
      </Button>
      {open && (
        <div className="dropdown-multiselect-options">
          <ul>
            <DropDownOptionComponent
              key="all"
              label="Select all"
              isSelected={allSelected}
              onClick={() =>
                onChange(allSelected ? [] : options?.map((o) => o.key) || [])
              }
              bold
            />
            {options?.map((o) => {
              const isSelected = selected.includes(o.key);
              const onClick = () => {
                const newSelected = isSelected
                  ? selected.filter((s) => s !== o.key)
                  : [...selected, o.key];
                onChange(newSelected);
              };
              return (
                <DropDownOptionComponent
                  key={o.key}
                  label={o.label}
                  isSelected={isSelected}
                  onClick={onClick}
                />
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
}
